<template>
    <travio-center-container grid-width='full'>
      <h2 style="color:#636363" class="mb-1">Delivery Fee Manager</h2>
      <vs-breadcrumb :separator="'chevron_right'" class="mb-3">
        <li>
          <router-link class="vs-breadcrumb--link" :to="`/applications/${applicationId}/currencyExchange`">Currency Exchange</router-link>
          <span aria-hidden="true" class="separator notranslate vs-breadcrum--separator material-icons">
            chevron_right
          </span>
        </li>
        <li>
          <span class="vs-breadcrumb--text vs-breadcrumb-text-primary">Delivery Fee Manager</span>
        </li>
      </vs-breadcrumb>
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button class="mr-4 sm:mb-0 mb-2" color="primary" @click="onAddDeliveryFee" type="filled">Add</vs-button>      
            <vs-input
              class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
          </div>
        </div>
         <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="deliveryFeeList"
          :gridOptions="gridOptions"
          :autoGroupColumnDef="autoGroupColumnDef"
          rowSelection="single"
          :pagination="false"
          :context="context"
          :suppressPaginationPanel="true"
        >
        </ag-grid-vue>
        
        
      </vx-card>
    
    </travio-center-container>
  </template>
  
  <script>
      
  import {AgGridVue} from "ag-grid-vue";
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
  import Vue from "vue"
  import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';
  import CellRendererActiveCheckbox from './cell-renderers/CellRendererActiveCheckbox.vue'
  import CellRendererBranchActions from './cell-renderers/CellRendererBranchActions.vue'
  import { format } from 'date-fns'
  
  export default {
    components: {
      AgGridVue,
      CellRendererActiveCheckbox,
      CellRendererBranchActions
    },
    props: {
      applicationId: { required: true}
    },
    data () {
      return {
        deliveryFeeList: [],
        autoGroupColumnDef: null,
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        defaultColDef: null,
        rowSelection: null,
        cellRendererComponents: {
          CellRendererActiveCheckbox,
          CellRendererBranchActions
        },
        context: null,
        searchQuery: ''
      }
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser;
      },
    },
    setup () {
      const { appHasFeature } = useAppFeatureAccess();
      return { appHasFeature }
    },
    async created () {
      //Check if user has acccess to the app
      if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
        this.$router.push('/error-404')
      }
  
      if (!this.appHasFeature('currencyexchange')) {
        this.$router.push('/error-404')
      }
      // this.deliveryFeeList = [
      //   { id: 1, name: 'Import and duties', restrictions: 'None', estimateDays: 7, price: 1200, currency: 'GBP', isActive: true},
      //   { id: 2, name: 'Service Fee', restrictions: 'Must be within 10 km radius', estimateDays: 14, price: 1200, currency: 'USD', isActive: true},
      // ]
  
      this.$vs.loading()
  
      try {
        const response = await this.$http.get(`api/currencyExchanges/apps/${this.applicationId}/deliveryFees`)
        this.deliveryFeeList = response.data
  
      } catch (error) {
        this.$_notifyFailure(error)
      }
  
      this.$vs.loading.close();
  
    },

    beforeMount() {
  
      this.gridOptions = {};
  
      // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
      this.columnDefs =  [
        { field: 'id', hide: true },
        { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererBranchActions) },
        { headerName: 'Fee Name', field: 'name', width: 200,  suppressSizeToFit: false },
        { headerName: 'Restrictions', field: 'restrictions', width: 280,  suppressSizeToFit: false },
        { headerName: 'Est. Delivery Days', field: 'estimateDays', width: 120,  suppressSizeToFit: false },
        { headerName: 'Price', field: 'price', width: 120,  suppressSizeToFit: false },
        { headerName: 'Currency', field: 'currency', width: 120,  suppressSizeToFit: false },
        { headerName: 'Active', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActiveCheckbox) },
        { field: 'isActive', hide: true },
      ]
  
      this.defaultColDef = {
        flex: 1,
        minWidth: 60,
        sortable: true,
        resizable: true,
        suppressMenu: true
      }
  
      this.autoGroupColumnDef = { minWidth: 200 };
      
      //This will make methods from this accessible to cell renderer
      this.context = { componentParent: this }
  
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
      this.gridApi.sizeColumnsToFit();
  
    },
    methods: {
      updateSearchQuery (val) {
        this.gridApi.setQuickFilter(val)
      },
      onAddDeliveryFee () {
        this.$router.push({name: 'application-currency-exchange-delivery-fees-add', 
          params: { 
            applicationId: this.applicationId,
          }
        })
      },
      editDeliveryFee (deliveryFeeId) {
        this.$router.push({name: 'application-currency-exchange-delivery-fees-edit', 
          params: { 
            applicationId: this.applicationId,
            deliveryFeeId: deliveryFeeId 
          }
        })
      },
      async deleteDeliveryFee (deliveryFeeId) {
        this.$vs.loading()
        try {
          const response = await this.$http.delete(`api/currencyExchanges/apps/${this.applicationId}/deliveryFees/${deliveryFeeId}`)
          this.deliveryFeeList = this.deliveryFeeList.filter(x => x.id != deliveryFeeId)
          this.$_notifySuccess('Successfully deleted delivery fee.');
  
        } catch (error) {
          this.$_notifyFailureByResponseData(error.response.data)
        }
  
        this.$vs.loading.close();
      },
    }
  }
  </script>
  
  <style>
  
  </style>